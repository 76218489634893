













import { Component, Vue } from 'vue-property-decorator';
import $ from 'jquery';
@Component({
  components: {
    InsHeader: () => import('@/components/business/header/InsHeader.vue'),
    InsFooter: () => import('@/components/business/footer/InsFooter.vue'),
    InsSlideMenu: () => import('@/components/business/header/InsSlideMenu.vue'),
    InsMenuLayout: () => import('@/components/business/header/InsMenuLayout.vue'),
    // InsCopyright: () => import('@/components/business/footer/InsCopyright.vue'),
    InsSidebar: () => import('@/components/business/header/InsSidebar.vue')
  }
})
export default class RNPLayout extends Vue {
  get isMobile () {
    return this.$store.state.isMobile;
  }
}
